import { useAppSelector, Selectors } from "Store";

type ChecklistTableHeaderComponentProps = {
    checklistUid: string;
}

export default function ChecklistTableHeaderComponent(props: ChecklistTableHeaderComponentProps) {
    const checklist = useAppSelector(() => Selectors.checklists.get(props.checklistUid));
    const { columns } = checklist;
    const { names, order } = columns;

    const actualNames:Array<string> = [];

    for(const cid of order) {
        actualNames.push(names[cid]);
    }

    return (
        <thead>
            <tr>
                {actualNames.map((name, index) => <td key={order[index]}>{name}</td>)}
            </tr>
        </thead>
    );
}