import { useAppSelector, Selectors, useAppDispatch } from "Store";
import TableCell from "./ChecklistTableCell.component";
import { ChecklistTableCell } from "./types";
import { Actions } from "Store";

type ChecklistTableLineComponentProps = {
    checklistUid: string;
    lineUid:string;
}

export default function ChecklistTableLineComponent(props: ChecklistTableLineComponentProps) {
    const checklist = useAppSelector(() => Selectors.checklists.get(props.checklistUid));
    const line = useAppSelector(() => Selectors.lines.getLine(props.lineUid));
    const dispatch = useAppDispatch();

    const { columns } = checklist;
    const { types, order } = columns;

    const cells:Array<ChecklistTableCell> = [];

    for(const cid of order) {
        cells.push({
            id:cid,
            value:line.values[cid],
            type:types[cid],
        });
    }

    const onChange = (newValue:any, cell:ChecklistTableCell) => {
        console.log(newValue, cell);
        dispatch(Actions.lines.updateLine({
            lineUid:props.lineUid,
            values: {
                ...line.values,
                [cell.id]: newValue,
            }
        }));
    }

    return (
        <tr className="h-9">
            {cells.map((cell, index) => <TableCell onChange={(nv) => { onChange(nv, cell) }} key={cell.id} {...cell} />)}
        </tr>
    );
}