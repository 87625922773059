import { useEffect, useState } from "react";
import { useAppDispatch, Actions } from "Store";
import ChecklistTable from "Components/ChecklistTable/ChecklistTable.component";
import Loading from "Components/Loading/Loading.component";

export default function LoggedInPage() {
    const dispatch = useAppDispatch();
    let [ loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(Actions.checklists.getAllChecklists(null)).then(() => {
            setLoading(false)
        })
    },[]);

    if(loading) {
        return <Loading />;
    }

    return (
        <div className="flex overflow-hidden">
            <ChecklistTable checklistUid="ywIRQh2XUZQG0sfccTyeSbguFzL2/lists/lists/flInIsqdjCPUtmWGSzGX" />
        </div>
    )
}