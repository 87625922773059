import { BaseSyntheticEvent } from "react";

type CheckboxCellComponentProps = {
    value:boolean;
    onChange?:(newValue:boolean) => void;
}

export default function CheckboxCellComponent(props:CheckboxCellComponentProps) {
    const checkboxChange = (e:any) => {
        if(props.onChange) props.onChange(e.target.checked)
    }

    return (
        <td>
            <input
                type="checkbox"
                checked={props.value}
                style={{transform:"scale(2)"}}
                onChange={checkboxChange}
            />
        </td>
    )
}