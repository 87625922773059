import { store } from "Store/cfg/configureStore";
import { RootState } from "../types";
import { linesAdapter } from "./lines.adapter";

const checklistsSelector =  linesAdapter.getSelectors<RootState>((state) => state.lines);

export const getLinesForChecklist = (clUid:string) => checklistsSelector.selectAll(store.getState()).filter(l => {
    return l.checklistUid == clUid;
});

export const getLine = (lineUid:string) => checklistsSelector.selectById(store.getState(), lineUid);