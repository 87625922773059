import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../types";
import { checklistsAdapter } from "./checklists.adapter";
import * as Actions from "./checklists.actions";


export const checklistsSlice = createSlice({
    name: "checklists",
    initialState: checklistsAdapter.getInitialState(),
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(Actions.getAllChecklists.fulfilled, (s,a) => {
            checklistsAdapter.setMany(s, a.payload);
        });
    })
});
