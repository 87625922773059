import './App.css';
import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Components/Layouts/Layout.component";
import LogInPage from "./Pages/LogIn/LogIn.page";
import LoggedInPage from "./Pages/LoggedIn/LoggedIn.page";
import routes from "Routes";
import Config from "./config";
import { useAppDispatch, Actions } from "Store";

function App() {
    const dispatch = useAppDispatch();
    let [hasUser, setHasUser] = useState(false);

    Config.firebase.$authStateChanged.subscribe((authChanged) => {
        console.log("user changed", authChanged);
        setHasUser(authChanged.hasUser);

        if(authChanged.hasUser) {
            dispatch(Actions.me.setMyUid(authChanged.uid));
        }
    });

    if(!hasUser) {
        return <LogInPage />
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                {/* <Route path="/" element={<Navigate to={routes.defaultAsFunction} replace /> }></Route> */}
                <Route path="/" element={<LoggedInPage />} />
            </Route>
        </Routes>
    );
}

export default App;
