import { Firestore, doc, collection } from "firebase/firestore/lite";
import { Checklist, ChecklistColumnsConfiguration, ChecklistLine } from "Model";
import { cr, dr, FirebaseChecklist, FirebaseChecklistLine, FirebaseColumnsDefinition, FirebaseUserList } from "./firebase.types";

/*
    /c   /d   /c    /d      /c    /d
    /{uid}/lists/lists/listUid/lines/lineUid
          + count     +

*/

// /{uid}
export function firebaseUserReference(uid:string, firestore:Firestore):cr<unknown> {
    return collection(firestore, uid) as cr<unknown>;
}

// /{uid}/lists/
export function firebaseListsDocumentReference(uid:string, firestore:Firestore):dr<FirebaseUserList> {
    return doc(firebaseUserReference(uid, firestore), "lists") as dr<FirebaseUserList>;
}

// /{uid}/lists/lists/
export function firebaseListsCollectionReference(uid:string, firestore:Firestore):cr<FirebaseChecklist> {
    return collection(firebaseListsDocumentReference(uid, firestore), "lists") as cr<FirebaseChecklist>;
}

// /{uid}/lists/lists/{clUid}
export function firebaseChecklistReference(uid:string, clUid:string, firestore:Firestore):dr<FirebaseChecklist> {
    return doc(firebaseListsCollectionReference(uid, firestore), clUid) as dr<FirebaseChecklist>;
}

// /{uid}/lists/lists/{clUid}/lines
export function firebaseChecklistLinesReference(clUid:string, firestore:Firestore):cr<FirebaseChecklistLine> {
    return collection(firestore, clUid, "lines") as cr<FirebaseChecklistLine>;
}

// /{uid}/lists/lists/{clUid}/lines
export function firebaseChecklistLineReference(lineUid:string, firestore:Firestore):dr<FirebaseChecklistLine> {
    return doc(firestore, lineUid) as dr<FirebaseChecklistLine>;
}

export function toChecklist(uid:string, fbChecklist:FirebaseChecklist): Checklist {
    if(!fbChecklist || !uid) throw new Error(`${uid} - ${JSON.stringify(fbChecklist)}`)

    const cl:Partial<Checklist> = {
        uid,
        name:fbChecklist.name,
        count: fbChecklist.count,
        columns: toColumnDefinition(fbChecklist.columns || {} as FirebaseColumnsDefinition),
    }

    return cl as Checklist;
}

export function toColumnDefinition(fbColDef:FirebaseColumnsDefinition): ChecklistColumnsConfiguration {
    if(!fbColDef) throw new Error(`fbColDef is null`);

    const def:Partial<ChecklistColumnsConfiguration> = {
        names: {...(fbColDef.names || {})},
        order: [...(fbColDef.order || [])],
        types: {...(fbColDef.types || {})},
    }

    return def as ChecklistColumnsConfiguration;
}

export function toChecklistLine(uid:string, checklistUid:string, fbClLine:FirebaseChecklistLine): ChecklistLine {
    if(!fbClLine || !uid) throw new Error(`${uid} - ${JSON.stringify(fbClLine)}`)

    const cl:Partial<ChecklistLine> = {
        uid,
        checklistUid,
        values: {
            ...fbClLine
        }
    }

    return cl as ChecklistLine;
}

