import { configureStore,  } from '@reduxjs/toolkit';
import { checklistsSlice } from "Store/checklists";
import { MeReducer } from "Store/me";
import { linesSlice } from "Store/lines";

export const store = configureStore({
    reducer: {
        checklists: checklistsSlice.reducer,
        me: MeReducer,
        lines: linesSlice.reducer,
    },
    devTools:true,
});

