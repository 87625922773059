
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CellValue, Checklist, ColumnId } from "Model";
import Api from "Services/Api";
import { RootState } from "Store/types";
import { actionNames } from "./lines.actionsNames";

type GetLines = {
    checklistId:string;
}

export const getLines = createAsyncThunk(
    actionNames.getLines,
    async (arg:GetLines = null, thunkAPI) => {
        return await Api.Instance.checklists.getChecklistLines(arg.checklistId);
    }
);

type UpdateLine = {
    lineUid:string;
    values:{[columnId:ColumnId]: CellValue};
}

export const updateLine = createAsyncThunk(
    actionNames.updateLines,
    async (arg:UpdateLine = null, thunkAPI) => {
        return await Api.Instance.checklists.updateLine(arg.lineUid, arg.values);
    }
);
