import { createReducer } from "@reduxjs/toolkit";
import * as Actions from "./me.actions";

type MeState = {
    myUid: string;

}

const initialState:MeState = {
    myUid:null,
}

const meReducer = createReducer(initialState, (b) => {
    b.addCase(Actions.setMyUid, (s,a) => {
        s.myUid = a.payload;
    });
})

export { meReducer }