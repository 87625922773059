import { addDoc, collection, Firestore, getDocs, getFirestore, orderBy, query, updateDoc } from "firebase/firestore/lite";
import { CellValue, Checklist, ChecklistLine, ColumnId } from "Model";
import { firebaseChecklistLineReference, firebaseChecklistLinesReference, firebaseListsCollectionReference, firebaseListsDocumentReference, toChecklist, toChecklistLine } from "./references";

class ChecklistsApi {
    constructor(private firestore:Firestore) {

    }

    public async getAllChecklists(uid:string):Promise<Array<Checklist>> {
        const listReference = firebaseListsCollectionReference(uid, this.firestore);

        return getDocs(listReference)
            .then((result) => {
                const r:Array<Checklist> = [];
                for(const doc of result.docs) {
                    const checklistData = doc.data();

                    r.push(toChecklist(doc.ref.path, checklistData));
                }

                return r;
            });
    }

    public async getChecklistLines(clUid:string):Promise<Array<ChecklistLine>> {
        const linesReference = firebaseChecklistLinesReference(clUid, this.firestore);
        const q = query(linesReference, orderBy("col-0"))
        return  getDocs(q)
        .then((result) => {
            const r:Array<ChecklistLine> = [];
            for(const doc of result.docs) {
                const checklistData = doc.data();

                r.push(toChecklistLine(doc.ref.path, clUid, checklistData));
            }

            return r;
        });
    }

    public async updateLine(lineUid:string, values:{[columnId:ColumnId]: CellValue}):Promise<boolean> {
        const lineReference = firebaseChecklistLineReference(lineUid, this.firestore);

        return updateDoc(lineReference, values).then(() => true);
    }

    public async insertLines():Promise<void> {
        // 011
        // /ywIRQh2XUZQG0sfccTyeSbguFzL2/lists/lists/flInIsqdjCPUtmWGSzGX/lines/Iky8rgLRmkMplmlMkKhR
        const lines = ["011",
        "6 nimmt!",
        "7 Wonders",
        "7 Wonders - Extension Cities'",
        "7 Wonders : Architects",
        "7 Wonders : Île de Catane",
        "Abenteuer Menschheit",
        "Abyss",
        "Agricola",
        "Agricola : X-Deck",
        "Airlines Europe",
        "À la Carte",
        "Alcatraz : The Scapegoat",
        "Alchimistes",
        "Alhambra : Das Würfelspiel",
        "Alles im Eimer",
        "Altiplano",
        "Amerigo",
        "Amyitis",
        "Andor",
        "Angkor",
        "Anno 1800",
        "Antike",
        "Aquasphere",
        "Archipelago",
        "Armadöra",
        "Arriala",
        "Artus et la Table Ronde",
        "Asara",
        "Atlantic Star",
        "Atlantis",
        "Au Feu !",
        "Augustus",
        "Aux Portes de Loyang",
        "Aux pierres du Dragon",
        "Ave Caesar",
        "Azteca",
        "Baccade",
        "Banzaï",
        "Battlestar Galactica",
        "Battlestar Galactica : Exodus",
        "Battlestar Galactica : Extension Pegasus",
        "Battlestar Galactica : Renouveau",
        "Blokus",
        "Bohnanza :  le Bizness des Haricots",
        "Bombay",
        "Bora Bora",
        "Boursicocotte",
        "Bruges",
        "Bruxelles 1893",
        "Burdigala",
        "Burgle Bros",
        "Byzanz",
        "Ca$h'n Gun$",
        "Ca$h'n Gun$ - Yakuzas",
        "Ca$h'n Gun$ Live",
        "Camel Up",
        "Cant Stop!",
        "Captain Sonar",
        "Carcassonne",
        "Carcassonne - Die Jäger und Sammler",
        "Carcassonne - À la Préhistoire",
        "Carcassonne : Der Fluss",
        "Carolus Magnus",
        "Carrousel",
        "Cartagena",
        "Casbah",
        "Castles of Mad King Ludwig",
        "Castor Party !",
        "Cavum",
        "Caylus",
        "Caylus Magna Carta",
        "Chasse Gardée !",
        "Chimera Station",
        "China",
        "Chinatown",
        "City of Horror",
        "Clippers",
        "Cléopâtre et la Société des Architectes",
        "Codenames",
        "Colosseum",
        "Colossus Arena",
        "Colt Express",
        "Concept",
        "Concordia",
        "Copié Collé",
        "Coyote",
        "Crazy Time",
        "Croc!",
        "Crôa!",
        "Cuba",
        "Cyclades",
        "Cœur de Dragon",
        "Das Zepter von Zavandor",
        "Dash! A whimsical race through Singapore",
        "De Cape & d'Épée",
        "Dead of Winter - A la croisée des chemins",
        "Dead of Winter - La Nuit la plus Longue",
        "Defenders of the realm",
        "Der Name der Rose",
        "Der Untergang von Pompeji",
        "Descendance",
        "Deus",
        "Diamant",
        "Dice Town",
        "Die Erbtante",
        "Die Sieben Siegel",
        "Die Speicherstadt",
        "Disque-Monde : Ankh-Morpork",
        "Dixit",
        "Dixit 2",
        "Dixit Odyssey",
        "Dogs of War",
        "Dominant species",
        "Dominion",
        "Dominion : Alchimie",
        "Dominion : Marché noir & Délégué",
        "Dominion : l'Intrigue",
        "Doom : The Boardgame",
        "Du Balai !",
        "Dune - Imperium",
        "Dungeon Fighter",
        "Dungeon Fighter",
        "Dungeon Lords",
        "Dungeon Petz",
        "Dungeon Twister",
        "Dungeon Twister : 3/4 joueurs",
        "Dungeon Twister : Paladins & Dragons",
        "Dynamit Joe",
        "Déclic !?",
        "Eclipse",
        "Egizia",
        "El Grande",
        "Elfenland",
        "Elixir",
        "Elixir : La Compil'",
        "Elysium",
        "Endeavor",
        "Eruption",
        "Escape - La malédiction du Temple",
        "Escape : Illusions",
        "Esquissé ?",
        "Étoiles filantes",
        "Euphoria",
        "Everdell (Matagot)",
        "Evo",
        "Expédition Altiplano",
        "Exxit",
        "Fais pas l'Âne",
        "Fame Us",
        "Familys",
        "Felinia",
        "Fifty Fifty",
        "Fight !",
        "Filou",
        "Finca",
        "Five Tribes",
        "Flash Point: Fire Rescue",
        "Florenza",
        "Formule Dé",
        "Freedom - Le Chemin de Fer Clandestin",
        "Funkenschlag",
        "Funkenschlag : Benelux/Europe Centrale",
        "Funkenschlag : Brasilien - Spanien & Portugal",
        "Funkenschlag : China/Korea",
        "Funkenschlag : Die ersten Funken",
        "Funkenschlag : France/Italie",
        "Galaxy Trucker",
        "Gang Of Four",
        "Gaïa",
        "Ghost Stories",
        "Ghost Stories : White Moon",
        "Goa",
        "Gods love dinosaurs",
        "Gosu",
        "Gouda ! Gouda !",
        "Grand Austria Hotel",
        "Great Western",
        "Guillotine",
        "Hanabi",
        "Hansa Teutonica",
        "Hase und Igel",
        "Hattari",
        "Hattrick",
        "Hawaii",
        "Hazienda",
        "Helvetia",
        "Heriss'Olym-Pik",
        "Heroquest",
        "Himalaya",
        "Historia",
        "Ho! Hisse!",
        "Hotel Samoa",
        "Huit minutes pour un empire",
        "Identik",
        "Iliade",
        "Illuminati",
        "Imagine",
        "In 80 Tagen um die Welt",
        "Innovation",
        "Intrigo",
        "Invasions",
        "Isla Dorada",
        "Isle of Skye",
        "Istanbul",
        "Jamaica",
        "Jenseits von Theben",
        "Joomba !",
        "Jungle",
        "Jungle Speed",
        "Jungle Speed - L'extension",
        "K2",
        "Kahuna",
        "Kart sur Glace",
        "Keltis",
        "Kenjin",
        "Key Largo",
        "Keyflower",
        "Keythedral",
        "Khronos",
        "Kingdom Builder",
        "Kingdom Builder - Extension 'Capitol'",
        "Kingdom Builder - Extension 'Nomads'",
        "Kingdoms",
        "Konito ?",
        "Kremlin",
        "Krysis",
        "Kréo",
        "L'Âge de Pierre",
        "L'Âge de Pierre : L'extension",
        "L'Île interdite",
        "La Città",
        "La Crypte de la Créature",
        "La Fièvre de l'Or",
        "La Glace et le Ciel",
        "La Gloire de Rome",
        "La Guerre des Moutons",
        "La Havane",
        "La Ruche",
        "La Traversée du Désert",
        "Lady Alice",
        "Land Unter",
        "Last Will",
        "Le Collier de la Reine",
        "Le Diable dans la Bouteille",
        "Le Désert Interdit",
        "Le Grand Dalmuti",
        "Le Havre",
        "Le Marché de Samarkand",
        "Le Roi des Nains",
        "Le Seigneur des Anneaux - La Quête",
        "Le Seigneur des Anneaux - Le Duel",
        "Le Trône de Fer",
        "Le jeu des 9 Tables",
        "Le signe des Anciens",
        "Lemming Mafia",
        "Leonardo Da Vinci",
        "Les 3 Mousquetaires",
        "Les Apprentis de la Manufacture",
        "Les Aventuriers du Rail",
        "Les Aventuriers du Rail - Asie & Asie Légendaire",
        "Les Aventuriers du Rail - Le jeu de Cartes",
        "Les Aventuriers du Rail - Édition Märklin",
        "Les Aventuriers du Rail : Europe",
        "Les Aventuriers du Rail : Europe 1912",
        "Les Aventuriers du Rail : Extension Bretagne",
        "Les Aventuriers du Rail : Suisse",
        "Les Aventuriers du Rail : USA 1910",
        "Les Chevaliers de la Table Ronde",
        "Les Chevaliers de la Table Ronde : La Compagnie de Merlin",
        "Les Châteaux de Bourgogne",
        "Les Colons de Catane",
        "Les Colons de Catane : Extension 5/6 joueurs",
        "Les Colons de Catane : Les Marins de Catane",
        "Les Colons de Catane : Villes & Chevaliers",
        "Les Demeures de l'Épouvante",
        "Les Dragons du Mekong",
        "Les Géants de l'île de Pâques",
        "Les Loups-Garous de Thiercelieux",
        "Les Palais de Carrara",
        "Les Piliers de la Terre",
        "Les Piliers de la Terre : L'extension",
        "Les Princes de Florence",
        "Les petites crapules, pagaille dans le bus !",
        "Lewis & Clark",
        "Libertalia",
        "Liens de sang",
        "Linq",
        "Living Forest",
        "Lobo 77",
        "London",
        "Loony Quest",
        "Louis XIV",
        "Loup Garou Pour Une Nuit",
        "Luna",
        "L’Année du Dragon",
        "MASTERISLANDS",
        "Magna Grecia",
        "Mah-Jong",
        "Maharadjah",
        "Maharaja",
        "Mamma Mia !",
        "Manila",
        "Maracaïbo",
        "Mare Nostrum",
        "Mare Nostrum",
        "Mare Nostrum : extension mythologique",
        "Marrakech",
        "Mascarade",
        "Math' Max",
        "Medici (édition 2005)",
        "Medina",
        "Memoire 44 : Battle Maps 3",
        "Metropolys",
        "Meuterer",
        "Mexica",
        "Mice and Mystics",
        "Micro-Mutants",
        "Micro-Mutants 2",
        "Mirogolo",
        "Mississippi Queen",
        "Mister X",
        "Modern Art",
        "Mombasa",
        "Mord im Arosa",
        "Mort ou Vif",
        "Mot Malin",
        "Mow",
        "Mr. Jack",
        "Mundus Novus",
        "Mykerinos",
        "Mykerinos : Le Nil",
        "Myrmes",
        "Mysterium",
        "Mystère à l'abbaye",
        "Mystères",
        "Mâamut",
        "Méditerranée",
        "Mémoire 44",
        "Mémoire 44 : Air Pack",
        "Mémoire 44 : Battle Maps 1",
        "Mémoire 44 : Battle Maps 2",
        "Mémoire 44 : Front Est",
        "Mémoire 44 : Pacific Theater",
        "Mémoire 44 : Terrain Pack",
        "Mémoire 44 : Théâtre méditerranéen",
        "Métro",
        "Nations",
        "Naufragés",
        "Navegador",
        "Niagara",
        "Nicht die Bohne!",
        "Ninja : Legend Of The Scorpion Clan",
        "No Panic!",
        "Northwest Passage",
        "Nosferatu",
        "Notre Dame",
        "Nouveaux mondes",
        "Nuns on the Run",
        "Néfertiti",
        "Négoces",
        "Offrandes",
        "One Night Ultimate Werewolf Daybreak",
        "Ora & Labora",
        "Orcs Orcs Orcs",
        "Oriflamme",
        "Outlive",
        "Paf le singe !",
        "Palais Royal",
        "Palazzo",
        "Paleo",
        "Pandemic: Reign of Cthulhu",
        "Pandémie",
        "Pandémie : Au seuil de la catastrophe",
        "Panthéon",
        "Papayoo",
        "Paris 1800",
        "Pergamon",
        "Perudo",
        "Petits Meurtres et Faits divers",
        "Pickomino",
        "Pictomania",
        "Pillards de la mer du Nord",
        "Pioneers",
        "Piranha Pedro",
        "Pirates du Nouveau Monde",
        "Pit",
        "Pousse-Monstre",
        "Privacy",
        "Puerto Rico",
        "Qin",
        "Québec 1608 - 2008",
        "Race for the Galaxy",
        "Race for the Galaxy : Au bord de l'Abîme",
        "Race for the Galaxy : Rebelles contre Imperium",
        "Race for the Galaxy : Tempête en Formation",
        "Railroad Tycoon : The Boardgame",
        "Rajas of the Ganges",
        "Rallyman (3e édition)",
        "Rasende Roboter",
        "Res Publica Romana",
        "Richard Cœur de Lion",
        "Ricochet Robot",
        "Rivière d'Enfer",
        "Roborally",
        "Roll for the Galaxy",
        "Roll through the Ages",
        "Room 25",
        "Room 25 Saison 2",
        "Russian Railroads",
        "Régents",
        "Rök",
        "Saboteur",
        "Saboteur : Les mineurs contre-attaquent !",
        "Saint Malo",
        "Salem",
        "San Juan",
        "Sankt Petersburg",
        "Sanssouci",
        "Santa Cruz",
        "Santiago",
        "Santiago De Cuba",
        "Sapiens",
        "Schatzkiste",
        "Schwarzer Freitag",
        "Seasons",
        "Service Compris",
        "Settlers - Naissance d'un Empire",
        "Shadow Hunters",
        "Shakespeare",
        "Shitenno",
        "Shogun",
        "Sidibaba",
        "Signorie",
        "Skull & Roses",
        "Small World",
        "Small World : Contes et Légendes",
        "Small World : Honneur aux Dames !",
        "Small World : Les Chefs de Small World",
        "Small World : Maauuudits !",
        "Small World : Même Pas Peur",
        "Snow Tails",
        "Sole Mio",
        "Space Alert : La nouvelle frontière",
        "Space Hulk - Death Angel",
        "Splendor",
        "Sporz Original Outbreak",
        "Squad Seven",
        "Stand Up !",
        "Stand Up ! Saison 2",
        "Star Wars: Rebellion",
        "Steam - Les Voies de la Richesse",
        "Stupide Vautour",
        "Sub Terra",
        "Suburbia",
        "Surprises",
        "Survive - Escape from Atlantis !",
        "Sushizock im Gockelwok",
        "Takenoko",
        "Takenoko Chibis",
        "Tales of the Arabian Nights",
        "Taluva",
        "Target Earth",
        "Tasso",
        "Tchin Tchin",
        "Terra Mystica",
        "Terraforming Mars",
        "That's Life!",
        "The Adventurers : La Pyramide d'Horus",
        "The Adventurers : le Temple de Chac",
        "The Castles of Burgundy: The Card Game",
        "The Game",
        "The HellGame",
        "The Manhattan Project",
        "The Resistance",
        "The Resistance : Avalon",
        "The boss",
        "This War Of Mine : Le Jeu De Plateau",
        "Through the Ages",
        "Thunderbirds - le jeu coopératif",
        "Thunderstone",
        "Thurn et Taxis : L'Aventure Postale",
        "Thurn und Taxis : Glanz und Gloria",
        "Tigre & Euphrate",
        "Tikal",
        "Tikal II",
        "Time No Time",
        "Time's Up ! - édition Purple",
        "Time's Up ! Academy",
        "Timeline",
        "Tobago",
        "Tokaido",
        "Tokyo Train",
        "Tomahawk",
        "Torres",
        "Tournay",
        "Tours de Cochon",
        "Trader",
        "Trajan",
        "Trans America",
        "Trans Europa",
        "Troyes",
        "Tschak !",
        "Turfmaster",
        "Tzolk’in : Le calendrier maya",
        "Un Monde sans Fin",
        "Union Pacific",
        "Utopia",
        "V-Sabotage",
        "Vasco da Gama",
        "Verflixxt Nochmal !",
        "Verräter",
        "Via Nebula",
        "Vinci",
        "Wanted !",
        "Wanted ! : Dodge City",
        "Wanted - Dead or Alive",
        "Warhammer Quest: Silver Tower",
        "Wazabi",
        "Western Town",
        "Wings of War - Watch Your Back",
        "Wiraqocha",
        "XenoShyft Onslaught",
        "Ys",
        "Yspahan",
        "Ystari Box",
        "Zack",
        "Zauber Cocktail",
        "Zombicide : Rue Morgue",
        "Zoo party",
        "Zooloretto",
        "Zoondo - Ziatik",
        ];

        const linesReference = collection(this.firestore, "/ywIRQh2XUZQG0sfccTyeSbguFzL2/lists/lists/flInIsqdjCPUtmWGSzGX/lines");

        for(const line of lines) {
            await addDoc(linesReference, {
                "col-0":line
            })
        }

    }
}

class Api {
    public checklists:ChecklistsApi;

    private firestore:Firestore;
    private static instance:Api;
    public static get Instance():Api {
        if(this.instance == null) this.instance = new Api();
        return this.instance;
    }

    constructor() {
        const firestore = this.firestore = getFirestore();

        this.checklists = new ChecklistsApi(firestore);
    }
}

export default Api;