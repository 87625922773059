import { Link, Outlet } from "react-router-dom";
import routes from "Routes";
import styles from "./Layout.module.css";
import Sidebar from "../Sidebar/Sidebar.component";
import Header from "../Header/Header.component";
import { useState } from "react";

function LayoutComponent() {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default LayoutComponent;