import { useEffect, useState } from "react";
import { useAppSelector, Actions, useAppDispatch, Selectors } from "Store";
import Spinner from "Components/Spinner/Spinner.component";
import Line from "./ChecklistTableLine.component";

type ChecklistTableBodyComponentProps = {
    checklistUid: string;
}

export default function ChecklistTableBodyComponent(props: ChecklistTableBodyComponentProps) {
    const checklist = useAppSelector(() => Selectors.checklists.get(props.checklistUid));
    const lines = useAppSelector(() => Selectors.lines.getLinesForChecklist(props.checklistUid));
    const dispatch = useAppDispatch();

    let [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(Actions.lines.getLines({ checklistId: props.checklistUid }))
            .then(() => {
                setLoading(false);
            });
    }, [])

    if (loading) {
        return (
            <tbody>
                <tr className="h-9">
                    <td colSpan={checklist.count}><Spinner /> loading lines... </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {lines.map(l => <Line checklistUid={props.checklistUid} key={l.uid} lineUid={l.uid} />)}
        </tbody>
    );
}
