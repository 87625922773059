import { MeActions } from "./me";
import { ChecklistsActions, ChecklistSelectors } from "./checklists";
import { LinesActions, LinesSelectors } from "./lines";

export * from "./cfg/hooks";
export * from "./types";
export * from "./cfg/configureStore";

const Actions = {
    me: MeActions,
    checklists: ChecklistsActions,
    lines: LinesActions,
}

const Selectors = {
    checklists:ChecklistSelectors,
    lines: LinesSelectors,
}

export { Actions, Selectors };