import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup as firebaseSignInWithPopup } from "firebase/auth";
import { Subject } from "rxjs";

export type AuthStateChanged = {
    hasUser: boolean;
    name: string;
    avatar: string;
    uid: string;
}

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBidED0ZdSTBYKm1MTCpFMctKvfrV6vI2c",
    authDomain: "checklists-89f33.firebaseapp.com",
    projectId: "checklists-89f33",
    storageBucket: "checklists-89f33.appspot.com",
    messagingSenderId: "932239421437",
    appId: "1:932239421437:web:42d416609b99df9373f394"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const $authStateChanged: Subject<AuthStateChanged> = new Subject<AuthStateChanged>();

auth.onAuthStateChanged((user) => {
    console.log("auth, userChanged", user);
    $authStateChanged.next({ hasUser: user != null, avatar: user?.photoURL, name: user?.displayName, uid: user?.uid });
});

const provider = new GoogleAuthProvider();

provider.addScope("openid");
provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
provider.addScope("https://www.googleapis.com/auth/userinfo.email");


export function signInWithPopup() {
    firebaseSignInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //const credential = GoogleAuthProvider.credentialFromResult(result);
            //const token = credential.accessToken;
            //const user = result.user;
            return true;

        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...

            console.log("errorCode", errorCode);
            console.log("errorMessage", errorMessage);
            console.log("email", email);
            console.log("credential", credential);

            throw new Error(`${errorCode} - ${errorMessage}`);
        });
}

export async function logout() {
    await auth.signOut();
}