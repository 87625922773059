import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../types";
import { linesAdapter } from "./lines.adapter";
import * as Actions from "./lines.actions";
import { ChecklistLine } from "Model";


export const linesSlice = createSlice({
    name: "lines",
    initialState: linesAdapter.getInitialState(),
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(Actions.getLines.fulfilled, (s,a) => {
            linesAdapter.setMany(s, a.payload);
        });

        builder.addCase(Actions.updateLine.fulfilled, (s,a) => {

            linesAdapter.upsertOne(s, {
                uid:a.meta.arg.lineUid,
                values:a.meta.arg.values,
            } as Partial<ChecklistLine> as ChecklistLine)
        })
    })
});
