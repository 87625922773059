
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Checklist } from "Model";
import Api from "Services/Api";
import { RootState } from "Store/types";
import { actionNames } from "./checklists.actionsNames";


export const getAllChecklists = createAsyncThunk(
    actionNames.getAll,
    async (arg:any = null, thunkAPI) => {

        const store = thunkAPI.getState() as RootState;
        const myUid = store.me.myUid;
        console.log("getAllChecklists for", myUid);

        if(!myUid) {
            return Promise.resolve([] as Array<Checklist>);
        }

        return await Api.Instance.checklists.getAllChecklists(myUid);
    }
);
