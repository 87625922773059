import { useAppSelector, Selectors } from "Store";
import { ChecklistTableCell } from "./types";
import * as Cells from "./Cells";

type ChecklistTableCellProps = ChecklistTableCell & {
    onChange?:(newValue:any) => void;
}

export default function ChecklistTableCellComponent(props: ChecklistTableCellProps) {

    if(props.type == "string") {
        return <Cells.Text key={props.id} value={props.value} />
    }

    if(props.type == "checkbox") {
        return <Cells.Checkbox key={props.id} value={props.value} onChange={props.onChange} />
    }

    if(props.type == "number") {
        return <Cells.Number key={props.id} value={props.value} />
    }


    return <td>??</td>
}