import Config from "config"

export default function LogInPage() {

    const onGoogleSignIn = () => {
        Config.firebase.signInWithPopup();
    }

    return (
        <div>
            <button onClick={onGoogleSignIn}>
                Log in !</button>
        </div>
    )
}