import ChecklistTableHeader from "./ChecklistTableHeader.component";
import ChecklistTableBody from "./ChecklistTableBody.component";

type ChecklistTableComponentProps = {
    checklistUid: string;
}

export default function ChecklistTableComponent(props: ChecklistTableComponentProps) {

    return (
        <table className="w-full self-start">
            <ChecklistTableHeader checklistUid={props.checklistUid} />
            <ChecklistTableBody checklistUid={props.checklistUid} />
        </table>
    )
}